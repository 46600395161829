<template>
    <div>
        <Sidebar class="print:hidden" />
        <div :class="{ collapsed: sidebarCollapsed }" class="main-content pb-1 print:ml-0">
            <TopBar class="print:hidden" :options="clientSelectOptions" />
            <div class="pr-3">
                <router-view />
            </div>
        </div>
        <Dialog />
        <Drawer />
    </div>
</template>
<script>
import Vue from "vue";
import ConfirmationModal from "@/components/ConfirmationModal.vue";
import Api from "../pages/supplierInvoice/supplierInvoice.api";

Vue.component("ConfirmationModal", ConfirmationModal);

export default {
    async created() {
        var accountsResponse = await Api.getAccountPlan();
        if (accountsResponse) {
            this.$store.commit("supplierInvoice/setAccountsInfo", accountsResponse);
        }
    },
    components: {
        Drawer: () => import("@/components/drawer/drawer.vue"),
        Dialog: () => import("@/dialog/dialog.vue"),
        Sidebar: () => import(/* webpackChunkName: "AppLayoutSidebar" */ "@/components/sidebar/Sidebar"),
        TopBar: () => import(/* webpackChunkName: "AppLayoutTopbar" */ "@/components/topbar/TopBar"),
    },
    data() {
        return {
            menuCollapsed: false,
            clientSelectOptions: [],
        };
    },

    computed: {
        sidebarCollapsed() {
            return this.$store.state.sidebarCollapsed;
        },
    },
};
</script>
<style>
html,
body {
    min-height: 100%;
    height: 100%;
    background-color: #f0eff4;
}

.main-content {
    margin-left: 220px;
}

.main-content.collapsed {
    margin-left: 65px;
}

@media print {
    .main-content,
    .main-content.collapsed {
        margin-left: 0;
    }
}
</style>
